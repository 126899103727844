<template>
    <v-dialog
        v-model="dialog"
        width="550"
        transition="fab-transition"
    >
        <v-card class="pl-0 pr-0">
            <v-card-title>
                <v-icon class="mr-3">fas fa-lock</v-icon>
                <span class="description-selected">
                    Cerrar Caja
                </span> 
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
                <v-container>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                ref="amount_cash"
                                v-model="tillForm.amount_cash"
                                label="Monto en efectivo"
                                outlined
                                prefix="S/"
                                :rules="[
                                    () => !!tillForm.amount_cash || 'Es necesario ingresar monto en efectivo',
                                    () => (!!tillForm.amount_cash && parseFloat(tillForm.amount_cash) > 0) || 'Monto en efectivo debe ser mayor a cero'
                                ]"
                                hide-details="auto"
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="total_efectivo"
                                label="Monto esperado"
                                outlined
                                prefix="S/"
                                disabled
                                hide-details>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="tillForm.amount_transfer"
                                label="Monto en transferencias"
                                outlined
                                prefix="S/"
                                hide-details="auto">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="total_transfer"
                                label="Monto esperado"
                                outlined
                                prefix="S/"
                                disabled
                                hide-details>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="tillForm.amount_card"
                                label="Monto en tarjetas"
                                outlined
                                prefix="S/"
                                hide-details="auto">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="total_card"
                                label="Monto esperado"
                                outlined
                                prefix="S/"
                                disabled
                                hide-details>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="tillForm.amount_yape"
                                label="Monto en yape"
                                outlined
                                prefix="S/"
                                hide-details="auto">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="total_yape"
                                label="Monto esperado"
                                outlined
                                prefix="S/"
                                disabled
                                hide-details>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-container class="pt-0 pb-0">
                    <v-row>
                        <v-col cols="6">
                            <v-btn dark color="red" block @click="handleCancel">
                                Cancelar
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn dark 
                                color="green" 
                                block 
                                :disabled="processing"
                                @click="handleCloseTill">
                                Aceptar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>                    
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'CloseTill',
    data() {
        return {
            dialog: false,
            total_efectivo: null,
            processing: false,
            tillForm: {
                amount_cash: null,
                amount_card: null,
                amount_transfer: null,
                amount_yape: null
            }
        }
    },
    computed: {
        ...mapState('tills', [
            'selected_till',            
            'total',
            'total_cash',
            'total_card',
            'total_transfer',
            'total_yape'
        ])
    },
    methods: {
        ...mapActions('tills', [
            'storeOperation'
        ]),
        showDialog() {
            this.clearData();
            this.total_efectivo = this.total - (this.total_card + this.total_transfer + this.total_yape);
            this.dialog = !this.dialog;
        },
        clearData() {
            this.tillForm.amount = null;
            this.tillForm.description = null;
            this.tillForm.amount_cash = null;
            this.tillForm.amount_card = null;
            this.tillForm.amount_transfer = null;
            this.tillForm.amount_yape = null;
            this.clearErrors();
        },
        handleCancel() {
            this.clearData();
            this.dialog = false;
        },
        async handleCloseTill(){
            this.processing = true;
            if (!this.existErrors()) {
                let operation = {
                    till_id: this.selected_till.id,
                    type: 'cierre',
                    description: 'Cierre de caja',
                    amount: this.tillForm.amount_cash,
                    amount_card: this.tillForm.amount_card,
                    amount_transfer: this.tillForm.amount_transfer,
                    amount_yape: this.tillForm.amount_yape
                }

                await this.storeOperation(operation);
                this.dialog = false;
                this.processing = false;
            } else {
                this.processing = false;
            }
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.tillForm).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.tillForm[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.tillForm).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
    }
}
</script>