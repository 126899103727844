<template>
    <v-container class="pt-2">
        <!--
        <v-row>
            <v-col cols="12" class="pt-0">
                <v-autocomplete
                    v-model="sede_id"
                    :items="sedes"
                    label="Seleccione sede"
                    hide-details
                    outlined
                    :item-text="customText"
                    item-value="id"
                    clearable
                    @change="handleSelectSede"
                ></v-autocomplete>
            </v-col>
        </v-row>
        -->
        <v-row>
            <v-col cols="12">
                <v-autocomplete
                    v-model="office_id"
                    :items="offices"
                    label="Seleccione sucursal"
                    hide-details
                    outlined
                    :item-text="customText"
                    item-value="id"
                    clearable
                    @change="handleSelectOffice"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col cols="12" class="pt-0 pb-1">
                <v-container class="pt-0">
                    <!-- Esto debe estar en un bucle -->
                    <v-row v-for="till in tills" :key="till.id"
                        justify="center" 
                        :class="'row-till ' + getClassTillActive(till)">
                        <v-col cols="11" @click="hanldleSelectTill(till)" class="till-item">
                            <template>
                                <v-row>
                                    <v-col cols="12" class="pt-2 pb-1">
                                        <span class="till-name">{{ till.name }}</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" class="pb-0">
                                        <v-icon v-if="till.status=='abierta'" size="14" class="mr-2" color="blue">fas fa-lock-open</v-icon>
                                        <v-icon v-else size="14" class="mr-2" color="red">fas fa-lock</v-icon>
                                        
                                        <span v-if="till.status=='abierta'" class="till-open">Abierta a</span>
                                        <span v-else class="till-close">Cerrada</span>
                                    </v-col>
                                    <v-col cols="6" class="pb-0" v-if="till.status=='abierta'">
                                        <span v-if="till.open_date" class="float-right till-open open-hour">
                                            {{ till.open_date | moment("hh:mm a") }}
                                        </span>
                                    </v-col>
                                    <v-col v-else>
                                        <span v-if="till.close_date" class="float-right till-close close-hour">
                                            {{ till.close_date | moment("hh:mm a") }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" class="pt-0 pb-0">
                                        <v-icon size="14" class="mr-2" color="green">fas fa-money-bill</v-icon>
                                        <span class="till-income">Ingresos</span>
                                    </v-col>
                                    <v-col cols="6" class="pt-0 pb-0">
                                        <span class="float-right till-income income-amount">
                                            {{ till.total_incomes | currency('S/') }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

        <open-till ref="openTill"></open-till>
    </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';

import OpenTill from './OpenTill';

export default {
    name: 'TillList',
    components: {
        OpenTill
    },
    data() {
        return {
            sede_id: null,
            office_id: null,
            till_seleted: null
        }
    },
    computed: {
        ...mapState('authentication', [
            'till_user_id',
            'company_id'
        ]),
        ...mapState('sedes', [
            'sedes'
        ]),
        ...mapState('offices', [
            'offices'
        ]),
        ...mapState('tills', [
            'tills',
            'selected_till'
        ])
    },
    methods: {
        ...mapActions('sedes', [
            'getSedes'
        ]),
        ...mapActions('offices', [
            'getOffices',
            'run_set_offices'
        ]),
        ...mapActions('tills', [
            'getAvailable',
            'select_till',
            'getOperations',
            'empty_tills',
            'empty_operations',
            'empty_summary',
            'getLastClosed'
        ]),
        customText(item){
            let text = item.name
            
            return text
        },
        getClassTillActive(item) {
            let class_active = '';
            if (this.selected_till) {
                if (this.selected_till.id == item.id) {
                    class_active = 'till-selected';
                }
            }

            return class_active;
        },
        async handleSelectSede(value){
            if(value !== undefined && value !== null){
                let filters = {
                    sede_id: value
                }
                await this.getOffices(filters);
            } else {
                this.run_set_offices([]);
            }
        },
        async handleSelectOffice(value) {
            this.office_id = value;
            this.select_till(null);
            if(value !== undefined && value !== null){
                let filters = {
                    office_id: this.office_id,
                    till_id: this.till_user_id
                }
                await this.getAvailable(filters);
            }
        },
        async hanldleSelectTill(till){
            this.empty_operations();
            this.empty_summary();
            this.select_till(till);
            if (this.selected_till.status == 'abierta') {
                await this.getOperations();
            } else {
                await this.getLastClosed({
                    till_id: this.selected_till.id
                })
            }
        },
        async loadTills() {
            if (this.till_user_id) {
                let filters = {
                    till_id: this.till_user_id
                }
                await this.getAvailable(filters);
            } else{
                await this.getAvailable();
            }
        }
    },
    created() {
        this.getOffices({
            company_id: this.company_id
        });
        this.loadTills();
    },
    mounted() {
        this.empty_summary();
        this.empty_tills();
    }
}
</script>

<style scoped>
.row-till{
    border-bottom: 1px solid #d6d6d8;    
}

.till-selected{
    background-color: #e2e3e7 !important;
}

.till-item{
    cursor: pointer;
}

.till-name{
    font-size: 1.1rem !important;
    font-weight: 600;
}

.till-open{
    font-size: .85rem !important;
}

.till-close{
    font-size: .85rem !important;
    color: #F44336;
}

.open-hour{
    font-weight: 600;
    color: #2196F3;
}

.close-hour{
    font-weight: 600;
    color: #F44336;
}

.till-income{
    font-size: .85rem !important;
}

.income-amount{
    font-weight: 600;
    color: #4CAF50;
}
</style>