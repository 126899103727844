<template>
    <v-dialog
        v-model="dialog"
        width="350"
        transition="fab-transition"
    >
        <v-card class="pl-0 pr-0">
            <v-card-title>
                <v-icon class="mr-3">fas fa-cash-register</v-icon>
                <span class="description-selected">
                    Abrir Caja
                </span> 
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="tillForm.amount"
                                label="Monto de apertura"
                                outlined
                                prefix="S/"
                                hide-details>
                            </v-text-field>
                        </v-col>
                    </v-row>                    
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-container class="pt-0 pb-0">
                    <v-row>
                        <v-col cols="6">
                            <v-btn dark color="red" block @click="handleCancel">
                                Cancelar
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn dark color="green" block @click="handleOpenTill">
                                Aceptar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>                    
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'OpenTill',
    data() {
        return {
            dialog: false,
            tillForm: {
                id: null,
                amount: null
            }
        }
    },
    computed: {
        ...mapState('tills', [
            'selected_till'
        ])
    },
    methods: {
        showDialog() {
            this.clearData();
            this.tillForm.id = this.selected_till.id;
            this.dialog = !this.dialog;
        },
        clearData() {
            this.tillForm.id = null;
            this.tillForm.amount = null;
        },
        handleCancel() {
            this.clearData();
            this.dialog = false
        },
        handleOpenTill(){
            console.log('abrir caja');
        }
    }
}
</script>

<style scoped>

</style>