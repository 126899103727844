<template>
    <v-container class="pt-0 pl-0 pb-0">        
        <v-row >
            <v-col cols="12" style="text-align: center;">
                <div class="summary-item">
                    <v-card>
                        <v-card-text class="pb-1 pt-1">
                            <v-container class="pb-0 pt-1 pl-1 pr-1">
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-1">
                                        <span class="summary-label">Total</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0">
                                        <span class="summary-amount">
                                            {{ total | currency('S/') }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-2">
                                        <span class="summary-quantity">
                                            {{ number_operations }} Movimientos
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0 pl-0 pr-0">
                                        <hr class="line-total">
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
                <div class="summary-item">
                    <v-card>
                        <v-card-text class="pb-1 pt-1">
                            <v-container class="pb-0 pt-1 pl-1 pr-1">
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-1">
                                        <span class="summary-label">Monto Apertura</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0">
                                        <span class="summary-amount">
                                            {{ total_open | currency('S/') }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-2">
                                        <span class="summary-quantity">
                                            {{ number_open }} Movimientos
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0 pl-0 pr-0">
                                        <hr class="line-open">
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
                <!--
                <div class="summary-item">
                    <v-card>
                        <v-card-text class="pb-1 pt-1">
                            <v-container class="pb-0 pt-1 pl-1 pr-1">
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-1">
                                        <span class="summary-label">Ingresos</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0">
                                        <span class="summary-amount">
                                            {{ total_incomes | currency('S/') }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-2">
                                        <span class="summary-quantity">
                                            {{ number_incomes }} ingresos
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0 pl-0 pr-0">
                                        <hr class="line-incomes">
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
                -->
                <div class="summary-item">
                    <v-card>
                        <v-card-text class="pb-1 pt-1">
                            <v-container class="pb-0 pt-1 pl-1 pr-1">
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-1">
                                        <span class="summary-label">Egresos</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0">
                                        <span class="summary-amount">
                                            {{ total_expenses | currency('S/') }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-2">
                                        <span class="summary-quantity">
                                            {{ number_expenses }} egresos
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0 pl-0 pr-0">
                                        <hr class="line-expenses">
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
                
                <div class="summary-item">
                    <v-card>
                        <v-card-text class="pb-1 pt-1">
                            <v-container class="pb-0 pt-1 pl-1 pr-1">
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-1">
                                        <span class="summary-label">Total Efectivo</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0">
                                        <span class="summary-amount">
                                            {{ (total_open + total_incomes + total_cash - total_expenses) | currency('S/') }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-2">
                                        <span class="summary-quantity">
                                            {{ (number_open + number_incomes + number_cash + number_expenses) }} movimientos
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0 pl-0 pr-0">
                                        <hr class="line-incomes">
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
                
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pb-5" style="text-align: center;">                
                <div class="summary-item">
                    <v-card>
                        <v-card-text class="pb-1 pt-1">
                            <v-container class="pb-0 pt-1 pl-1 pr-1">
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-1">
                                        <span class="summary-label">V. Efectivo</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0">
                                        <span class="summary-amount">
                                            {{ total_cash | currency('S/') }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-2">
                                        <span class="summary-quantity">
                                            {{ number_cash }} ventas
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0 pl-0 pr-0">
                                        <hr class="line-sales">
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
                <div class="summary-item">
                    <v-card>
                        <v-card-text class="pb-1 pt-1">
                            <v-container class="pb-0 pt-1 pl-1 pr-1">
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-1">
                                        <span class="summary-label">V. Transferencias</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0">
                                        <span class="summary-amount">
                                            {{ total_transfer | currency('S/') }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-2">
                                        <span class="summary-quantity">
                                            {{ number_transfer }} ventas
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0 pl-0 pr-0">
                                        <hr class="line-transfer">
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
                <div class="summary-item">
                    <v-card>
                        <v-card-text class="pb-1 pt-1">
                            <v-container class="pb-0 pt-1 pl-1 pr-1">
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-1">
                                        <span class="summary-label">V. Tarjeta</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0">
                                        <span class="summary-amount">
                                            {{ total_card | currency('S/') }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-2">
                                        <span class="summary-quantity">
                                            {{ number_card }} ventas
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0 pl-0 pr-0">
                                        <hr class="line-card">
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
                <div class="summary-item">
                    <v-card>
                        <v-card-text class="pb-1 pt-1">
                            <v-container class="pb-0 pt-1 pl-1 pr-1">
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-1">
                                        <span class="summary-label">V. Yape</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0">
                                        <span class="summary-amount">
                                            {{ total_yape | currency('S/') }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-2">
                                        <span class="summary-quantity">
                                            {{ number_yape }} ventas
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-0 pl-0 pr-0">
                                        <hr class="line-yape">
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'TillSummary',
    computed: {
        ...mapState('tills', [
            'total',
            'number_operations',
            'total_sales',
            'number_sales',
            'total_incomes',
            'number_incomes',
            'total_expenses',
            'number_expenses',
            'total_cash',
            'number_cash',
            'total_transfer',
            'number_transfer',
            'total_card',
            'number_card',
            'total_yape',
            'number_yape',
            'total_open',
            'number_open',
            'total_canceled',
            'number_canceled'
        ])
    }
}
</script>

<style scoped>

.summary-item{
    display: inline-block;
    width: 18%;
    margin-right: 10px;
}

.summary-label{
    font-weight: 600;
    font-size: 1.1rem;
}

.summary-amount{
    font-weight: 600;
    font-size: 1.2rem;
}

.summary-quantity{
    font-size: 1rem;
}

.line-total{
    border: 2px solid #023145;
    border-radius: 2px;
}

.line-sales{
    border: 2px solid #303297;
    border-radius: 2px;
}

.line-incomes{
    border: 2px solid #50be9f;
    border-radius: 2px;
}

.line-expenses{
    border: 2px solid #ff4d4f;
    border-radius: 2px;
}

.line-card{
    border: 2px solid #2196F3;
    border-radius: 2px;
}

.line-transfer{
    border: 2px solid #ff9900;
    border-radius: 2px;
}

.line-yape{
    border: 2px solid #742284;
    border-radius: 2px;
}

.line-open{
    border: 2px solid #4CAF50;
    border-radius: 2px;
}

.line-canceled{
    border: 2px solid #B71C1C;
    border-radius: 2px;
}
</style>