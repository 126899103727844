<template>
    <v-container v-if="selected_till" fluid class="h-100 pt-0 pb-0">
        <v-row>
            <v-col cols="12" class="pt-1 pb-1">
                <till-summary></till-summary>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3" class="pt-1">
                <v-combobox
                    v-model="movement_type"
                    :items="movements"
                    label="Tipo de movimiento"
                    outlined
                    hide-details
                    @change="handleChangeMovementType"
                ></v-combobox>
            </v-col>
            <v-col cols="4" class="pt-1">
                <template>
                    <v-row>
                        <v-col cols="9" v-if="movement_type && movement_type.value==='transferencia'" class="pt-0">
                            <v-autocomplete
                                v-model="bank_account_id"
                                :items="accounts"
                                outlined
                                label="Cuenta Bancaria"
                                persistent-hint
                                clearable
                                :item-text="customTextAccount"
                                item-value="id"
                                hide-details
                                :filter="customFilterAccount"
                            >
                                <template v-slot:item="data">
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.bank.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ data.item.type }}: {{ data.item.number_account }}</v-list-item-subtitle>
                                </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="3" class="pt-0">
                            <v-btn dark color="green" @click="handleSearchOperations">
                                Mostrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>                
            </v-col>
            <v-col cols="5" class="pt-1">
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark v-on="on" class="float-right">
                            Acciones
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-if="selected_till.status == 'abierta' && this.$hasPermision('movimientos.close')" @click="handleCloseTill">
                            <v-list-item-title>
                                <v-icon class="mr-2" size="14" color="#023145">fas fa-lock</v-icon>
                                <span>Cerrar Caja</span>
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider v-if="selected_till.status == 'abierta'"></v-divider>
                        <v-list-item v-if="selected_till.status == 'abierta' && this.$hasPermision('movimientos.income')" @click="handleRegisterIncome">
                            <v-list-item-title>
                                <v-icon class="mr-2" size="14" color="#50be9f">fas fa-download</v-icon>
                                <span>Registrar Ingreso</span>
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider v-if="selected_till.status == 'abierta'"></v-divider>
                        <v-list-item v-if="selected_till.status == 'abierta' && this.$hasPermision('movimientos.expense')" @click="handleRegisterExpense">
                            <v-list-item-title>
                                <v-icon class="mr-2" size="14" color="#ff4d4f">fas fa-upload</v-icon>
                                <span>Registrar Egreso</span>
                            </v-list-item-title>
                        </v-list-item>
                        
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-data-table
                        :headers="headers"
                        :items="operations"
                        class="elevation-1 w-table table-crud"
                        height="58vh"
                    >
                        <template v-slot:item.type="{item}">
                            <span :class="'amount-'+item.type">{{ item.type }}</span>
                        </template>

                        <template v-slot:item.operation_date="{item}">
                            <span v-if="item.operation_date">{{ item.operation_date | moment("DD/MM/YYYY") }}</span>
                        </template>

                        <template v-slot:item.hour="{item}">
                            <span v-if="item.operation_date">{{ item.operation_date | moment("hh:mm a") }}</span>
                        </template>

                        <template v-slot:item.user_id="{item}">
                            <span>{{ item.fullname }}</span>
                        </template>

                        <template v-slot:item.amount="{item}">
                            <span :class="'amount-'+item.type">{{ item.amount | currency('S/') }}</span>
                        </template>

                        <template v-slot:item.actions="{item}">
                            <v-icon v-if="item.type === 'ingreso' || item.type === 'egreso'"
                                color="red"
                                size="18"
                                @click="handleDeleteOperation(item)">
                                far fa-times-circle
                            </v-icon>
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <income-till ref="incomeTill"></income-till>
        <expense-till ref="expenseTill"></expense-till>
        <close-till ref="closeTill"></close-till>
    </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';

import TillSummary from './TillSummary';
import IncomeTill from './IncomeTill';
import ExpenseTill from './ExpenseTill';
import CloseTill from './CloseTill';
import OpenTillVue from './OpenTill.vue';

export default {
    name: 'TillDetail',
    components: {
        TillSummary,
        IncomeTill,
        ExpenseTill,
        CloseTill
    },
    data() {
        return {
            movement_type: null,
            movements: [
                { text: 'Todos', value: 'todos'},
                { text: 'Apertura', value: 'apertura'},
                { text: 'Ingresos', value: 'ingreso'},
                { text: 'Egresos', value: 'egreso'},
                { text: 'Ventas Efectivo', value: 'efectivo'},
                { text: 'Ventas Transferencias', value: 'transferencia'},
                { text: 'Ventas Tarjeta', value: 'tarjeta'},
                { text: 'Ventas Yape', value: 'yape'},
                { text: 'Anulaciones de ventas', value: 'anulacion'},
            ],
            headers: [
                { text: 'TIPO', value: 'type', sortable: false, width: 80 },
                { text: 'FECHA', value: 'operation_date', sortable: false },
                { text: 'HORA', value: 'hour', sortable: false },
                { text: 'USUARIO', value: 'user_id', sortable: false },                
                { text: 'DESCRIPCIÓN', value: 'description', sortable: false },
                { text: 'BANCO', value: 'bank_description', sortable: false },
                { text: 'MONTO', value: 'amount', sortable: false },
                { text: '', value: 'actions', sortable: false}
            ],
            tillMovements: [],
            bank_account_id: null
        }
    },
    computed: {
        ...mapState('tills', [
            'selected_till',
            'operations'
        ]),
        ...mapState('accounts', [
            'accounts'
        ])
    },
    methods: {
        ...mapActions('tills', [
            'empty_operations',
            'getOperations',
            'deleteOperation'
        ]),
        ...mapActions('accounts', [
            'getAccounts',
        ]),
        handleRegisterIncome(){
            this.$refs.incomeTill.showDialog();
        },
        handleRegisterExpense(){
            this.$refs.expenseTill.showDialog();
        },
        handleCloseTill(){
            this.$refs.closeTill.showDialog();
        },
        handleChangeMovementType (movement) {
            this.bank_account_id = null;
        },
        async handleSearchOperations(){
            let filters = {
                type: this.movement_type.value,
                bank_account_id: this.bank_account_id
            }
            await this.getOperations(filters);
        },
        customTextAccount(item) {
            let text = item.bank.name;
            return text;
        },
        customFilterAccount(item, queryText, itemText) {
            let bank_name = item.bank.name;
            let textBank = bank_name.toLowerCase();
            let textNumberAccount = item.number_account;
            let searchText = '';
            if (queryText) {
                searchText = queryText.toLowerCase();
            }

            return (
                textBank.indexOf(searchText) > -1 ||
                textNumberAccount.indexOf(searchText) > -1
            );
        },
        handleDeleteOperation (operation) {
            this.$swal({
                    title: "¿Desea confirmar?",
                    text: "Confirma que desea eliminar el " + operation.type,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                }).then(async (result) => {
                    if(result.value){
                        if(result.value === true){
                            let data = {
                                till_id: this.selected_till.id,
                                till_operation_id: operation.id
                            }
                            await this.deleteOperation(data);
                        }
                    }
                })
        }
    },
    mounted() {
        this.empty_operations();
        this.getAccounts();
    }
}
</script>

<style scoped>

.amount-apertura{
    font-weight: 600;
    color: #4CAF50;
}

.amount-ingreso{
    font-weight: 600;
    color: #50be9f;
}

.amount-egreso{
    font-weight: 600;
    color: #ff4d4f;
}

.amount-efectivo{
    font-weight: 600;
    color: #3F51B5;
}

.amount-tarjeta{
    font-weight: 600;
    color: #2196F3;
}

.amount-yape{
    font-weight: 600;
    color: #742284;
}

.amount-transferencia{
    font-weight: 600;
    color: #ff9900; 
}

.amount-anulacion{
    font-weight: 600;
    color: #B71C1C; 
}
</style>