<template>
    <v-dialog
        v-model="dialog"
        width="350"
        transition="fab-transition"
    >
        <v-card class="pl-0 pr-0">
            <v-card-title>
                <v-icon class="mr-3">fas fa-upload</v-icon>
                <span class="description-selected">
                    Registrar Egreso
                </span> 
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                ref="amount"
                                v-model="tillForm.amount"
                                label="Monto de egreso"
                                outlined
                                prefix="S/"
                                :rules="[
                                    () => !!tillForm.amount || 'Es necesario ingresar monto',
                                    () => (!!tillForm.amount && parseFloat(tillForm.amount) > 0) || 'Monto debe ser mayor a cero'
                                ]"
                                hide-details="auto"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="tillForm.description"
                                label="Descripción"
                                outlined
                                hide-details>
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-container class="pt-0 pb-0">
                    <v-row>
                        <v-col cols="6">
                            <v-btn dark color="red" block @click="handleCancel">
                                Cancelar
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn dark 
                                color="green"
                                block 
                                :disabled="processing"
                                @click="handleSaveExpense">
                                Aceptar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>                    
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'ExpenseTill',
    data() {
        return {
            dialog: false,
            processing: false,
            tillForm: {
                amount: null,
                description: null
            }
        }
    },
    computed: {
        ...mapState('tills', [
            'selected_till'
        ])
    },
    methods: {
        ...mapActions('tills', [
            'storeOperation'
        ]),
        showDialog() {
            this.clearData();
            this.dialog = !this.dialog;
        },
        clearData() {
            this.tillForm.amount = null;
            this.tillForm.description = null;
            this.clearErrors();
        },
        handleCancel() {
            this.clearData();
            this.dialog = false;
        },
        async handleSaveExpense(){
            this.processing = true;
            if (!this.existErrors()) {
                let operation = {
                    till_id: this.selected_till.id,
                    type: 'egreso',
                    description: this.tillForm.description,
                    amount: this.tillForm.amount,
                    amount_card: null
                }

                await this.storeOperation(operation);
                this.dialog = false;
                this.processing = false;
            } else {
                this.processing = false;
            }
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.tillForm).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.tillForm[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.tillForm).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
    }
}
</script>