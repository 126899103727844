<template>
    <div class="h-100">
        <template>
            <v-row class="h-100">
                <v-col cols="3" class="section-list-tills pr-0">
                    <tills-list></tills-list>
                </v-col>
                <v-col cols="9" class="section-till-movements">
                    <div>
                        <till-detail v-if="selected_till && selected_till.status == 'abierta'"></till-detail>
                        <closed-till v-if="selected_till && selected_till.status == 'cerrada' && this.$hasPermision('movimientos.open')"></closed-till>
                    </div>                    
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import TillsList from '../../../components/tills/TillsList';
import TillDetail from '../../../components/tills/TillDetail';
import ClosedTill from '../../../components/tills/ClosedTill';

export default {
    name: 'TillMovements',
    components: {
        TillDetail,
        TillsList,
        ClosedTill
    },
    computed: {
        ...mapState('tills', [
            'selected_till'
        ])
    },
    methods: {
        ...mapActions('tills', [
            'select_till'
        ])
    },
    mounted() {
        this.select_till(null);
    }
}
</script>

<style scoped>
.section-list-tills{
    height: 100% !important;
    background-color: #f4f4f6 !important;
}

.section-till-movements{
    height: 100% !important;
    background-color: #ffffff !important;
}
</style>